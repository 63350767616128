<template>
  <div>
    <div v-if="sharingPossible" class="flex justify-center items-center">
      <button @click="share" class="bg-red-600 px-30 uppercase text-white py-5 flex items-center font-bold text-18">
        Del artikel
        <i class="bg-svg-share-white square-20 ml-10 bg-contain bg-center bg-no-repeat"></i>
      </button>
    </div>
    <div v-else class="mb-20 flex justify-center items-center">
      <a :href="`https://www.facebook.com/sharer/sharer.php?u=${absUrl}`" target="_blank" rel="nofollow" class="mx-10" aria-label="Del på Facebook">
        <div class="square-30 bg-contain bg-center bg-no-repeat bg-svg-facebook-black"></div>
      </a>
      <a :href="`https://twitter.com/intent/tweet?url=${absUrl}`" target="_blank" rel="nofollow" class="mx-10" aria-label="Del på Twitter">
        <div class="square-30 bg-contain bg-center bg-no-repeat bg-svg-twitter-black"></div>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * The sharing url.
 *
 * @type string
 */
const absUrl = ref('');

/**
 * Boolean to determine if browser support share api.
 * @type boolean
 */
const sharingPossible = ref(false);

/**
 * Trigger device sharing api.
 * Button to trigger function will only be visible if navigator.share is supported by the browser.
 *
 * @returns void
 */
const share = async (): Promise<void> => {
  const description = document.querySelector('meta[name="description"]');
  try {
    await navigator.share({
      title: document.title,
      text: description !== null ? String(description.getAttribute('content')) : '',
      url: window.location.href,
    });
    console.log('Successful share');
  } catch (error) {
    console.error('Error sharing', error);
  }
};

/**
 * Vue mounted hook.
 * Set the absUrl to current window.location.href.
 * Set the sharingPossible to true if browser support share api.
 *
 * @returns void
 */
onMounted(() => {
  absUrl.value = window.location.href;
  sharingPossible.value = !!navigator.share;
});
</script>
